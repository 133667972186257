@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600;700&display=swap');


@layer components {
  .btn {
    @apply px-2 py-1 mx-3 rounded-lg text-white text-xl transition ease-in-out hover:scale-110 active:scale-90;
  }

  .select-option {
    @apply bg-black border-black font-Fira px-2;
  }

  .description-link {
    text-decoration: underline;
    text-decoration-color: #101024; /* text-primary-100 */
    /* text-decoration-color: #12b865; hype-100 */
  }
}

select {
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

.snap {
  flex-flow: column nowrap;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
}

.description > ul, ol {
  padding: 0 1rem;
}

.description > ul, ol > li {
  list-style-type: disc;
}

.grow-into-view {
  animation: grow-into-view 0.5s ease-in-out;
}

@keyframes grow-into-view {
  0% {
    transform: translateY(-100%);
    clip-path: inset(100% 0 0 0);
  }
  100% {
    transform: translateY(0);
    clip-path: inset(0 0 0 0);
  }
}

.arrow {
  background: url('../public/assets/triangle-grey.svg') no-repeat;
  width: 55px;
  height: 47px;
}

.arrow:hover, .arrow-dark {
  background: url('../public/assets/triangle-dark.svg') no-repeat;
}

.shadow-neu {
  /* Meant for color #e3e3e6 */
  /* background: linear-gradient(145deg, #f3f3f6, #cccccf); */
  /* border: 2px solid #e3e3e3; */
  /* box-shadow: 20px 20px 60px #d5ad12,
              -20px -20px 40px #ffeb18; */

              
  /* box-shadow:  20px 20px 60px #191935,
                -20px -20px 40px #12122c; */
  /* box-shadow:  20px 20px 60px #2f2f2f,
                -20px -20px 40px #1c1c1c; */

  /* background: #e2e9f3; */
  /* box-shadow:  20px 20px 40px #c0c6cf, 
                -20px -20px 40px #ffffff; */
  /* background: #181818; */
  /* box-shadow:  20px 20px 60px #232323,
              -20px -20px 40px #2a2a2a; */
}

/* Requires 2 shadows and tailwind can't do 2 shadows */
.shadow-inside-top-and-bottom {
  box-shadow: inset 0 4rem 15px rgba(0, 0, 0, 0.2),
              inset 0 -5rem 15px rgba(0, 0, 0, 0.2);
}

.radial-gradient {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  background: radial-gradient(
    circle at 50% 50%,
    #40c1e1, 
    #40c1e194
  );
}

.rotate {
  transition-duration: 1.5s;
  transform: rotate3d(1, 5, 1, 360deg);
}

.expand {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-name: expand-and-contract;
}

.snap {
  flex-flow: column nowrap;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

@keyframes expand-and-contract {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  60% {
    transform: scale(2.1);
  }
  75% {
    transform: scale(0.7);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.spin-and-shrink {
  animation: spin-and-shrink 1s ease-in-out;
}

@keyframes spin-and-shrink {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(0) rotate(360deg);
  }
  100% {
    transform: scale(1) rotate(720deg);
  }
}

/* .test {
  color: #efc61f;
} */

/* :root {
  --hype-green: #12b865;
}
body {
  background-color: #282c34;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
}

.Vote-Button {
  color: white;
  border: none;
  padding: 10px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 7px;
}

.Hype {
  background-color: var(--hype-green);
}

.Hype:hover {
  background-color: #1a9b50;
}

.Hate {
  background-color: #ff4d4d;
}

.Hate:hover {
  background-color: #e83939;
} */

